
import * as Sentry from '@sentry/browser'
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  props: {
    to: {
      type: String,
      required: true,
    }
  },

  setup(props: PropertyIndexedKeyframes) {
    const router = useRouter()
    const route = useRoute()
    const url = ref(props.to)
    const { locale } = route.params

    const jumpTo = () => {
      router.push(`/${locale}/${url.value}`)
    }

    return {
      url,
      locale,
      jumpTo,
    }
  },
})
