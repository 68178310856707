
import * as Sentry from '@sentry/browser'
import { useTranslation } from '@/locales'
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue'
import { startSingleSignOut } from '@/auth'
import Dialog from '@/components/Layout/Dialog.vue'

export default defineComponent({
  components: {
    Dialog,
  },
  setup() {
    const { t } = useTranslation()
    const isOpen = ref(false)
    const isConfirm = ref(false)
    const inProgress = ref(false)
    const refMenu = ref<HTMLElement | null>(null)

    const open = () => (isOpen.value = !isOpen.value)

    const openConfirm = () => {
      isConfirm.value = true
      isOpen.value = false
    }

    const clickClose = () => {
      if (!inProgress.value) {
        isConfirm.value = false
      }
    }

    const click = (e: any) => {
      if (isOpen.value && refMenu.value != null) {
        if (!refMenu.value.contains(e.target)) {
          isOpen.value = false
        }
      }
    }

    const signOut = () => {
      inProgress.value = true
      startSingleSignOut()
    }

    onMounted(() => {
      window.addEventListener('click', click)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('click', click)
    })

    return {
      isOpen,
      refMenu,
      isConfirm,
      inProgress,
      clickClose,
      openConfirm,
      signOut,
      t,
      open,
    }
  },
})
