<template>
  <div class="relative min-h-full">
    <nav class="bg-gray-800">
      <div class="px-4 md:px-8">
        <div class="flex items-center justify-between h-16">
          <fa-icon
            icon="bars"
            class="text-white text-2xl mr-2 cursor-pointer"
            @click="onClick"
          />

          <div
            class="hidden md:flex flex-1 items-center justify-center sm:items-stretch sm:justify-start"
          >
            <div class="flex-shrink-0 flex items-left">
              <h1 class="text-white text-2xl">{{ t('CUSTOMER PORTAL') }}</h1>
            </div>
          </div>
          <div className="flex items-center">
            <div className="mr-1 md:mr-4">
              <ServiceMenu />
            </div>

            <User />
          </div>
        </div>
      </div>

      <div v-if="isOpen" id="mobile-menu" class="sm:hidden">
        <div
          v-for="(item, index) in navList"
          :key="index"
          class="px-2 pt-2 pb-3 space-y-1"
        >
          <NavLink
            :to="item.to"
            class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
          >
            {{ item.name }}
          </NavLink>
        </div>
        <a
          :href="agreements"
          class="block cursor-pointer px-4 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:outline-none"
          target="_blank"
        >
          {{ t('Agreements') }}
        </a>
      </div>
    </nav>
    <div v-if="isUnpaid && showUnpaid()" class="py-4 bg-blue-600">
      <div class="md:flex justify-center items-center">
        <h1 class="text-white font-bold text-xl md:mx-4">
          {{ t('Incompleted the payment of this month') }}
        </h1>
        <button
          class="bg-red-500 text-white px-4 py-1 rounded-md"
          @click="redirectTo('payment/card')"
        >
          {{ t('Go to payment') }}
        </button>
      </div>
    </div>
    <div v-if="deleteZone.length > 0" class="py-4 bg-red-600">
      <div class="md:flex justify-center items-center">
        <h1 class="text-white font-bold text-xl md:mx-4">
          {{ deleteZone }}
        </h1>
      </div>
    </div>

    <div class="flex min-h-full bg-gray-50">
      <nav
        v-if="isOpen"
        class="hidden md:block min-h-full w-80 shadow-sm bg-white pt-4 px-4 text-left"
      >
        <NavLink
          v-for="(item, index) in navList"
          :key="index"
          :to="item.to"
          class="block cursor-pointer px-4 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          :class="{ selected: isMe(item.to) }"
        >
          {{ item.name }}
        </NavLink>
        <a
          :href="agreements"
          class="block cursor-pointer px-4 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:outline-none"
          target="_blank"
        >
          {{ t('Agreements') }}
        </a>
      </nav>
      <div class="min-h-screen w-full py-4 px-3 overflow-hidden">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/browser'
import { get as getPaymentStatus } from '@/api/customer/payment/status'
import { get as getServiceStatus } from '@/api/customer/service/status'
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTranslation } from '@/locales'
import ServiceMenu from '@/components/Layout/ServiceMenu.vue'
import User from '@/components/Layout/User.vue'
import NavLink from '@/components/Layout/NavLink.vue'

export default defineComponent({
  components: {
    NavLink,
    ServiceMenu,
    User,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const isOpen = ref(true)
    const isUnpaid = ref(false)
    const { t, d } = useTranslation()
    const { locale } = route.params
    const agreements = ref('')

    const deleteZone = ref('')

    const navList = [
      { to: 'service/list', name: t('Service List') },
      { to: 'service/order', name: t('Buy') },
      { to: 'payment/invoices', name: t('Invoice') },
      { to: 'payment/history', name: t('Payment History') },
      { to: 'user/management', name: t('User management') },
    ]

    const showUnpaid = () =>
      !window.location.pathname.startsWith(`/${locale}/payment/card`)

    const isMe = (to: string) => {
      const result =
        window.location.pathname.startsWith(`/${to}`) ||
        window.location.pathname.startsWith(`/${locale}/${to}`)

      return result
    }

    const update = () => {
      agreements.value = `${process.env.VUE_APP_ORDER_APP}/${locale}/agreements`

      getPaymentStatus().then((paid) => (isUnpaid.value = !paid))
      getServiceStatus().then((status) => {
        const res = status.filter((s) => s.serviceName === 'qt-dns-zones')
        if (res.length && res[0].requireDescreaseAmount > 0) {
          const {
            contractEndDate,
            nextZoneNum,
            endDate,
            requireDescreaseAmount,
          } = res[0]
          if (
            contractEndDate != null &&
            nextZoneNum != null &&
            endDate != null
          ) {
            deleteZone.value = t(
              'You can use {nextZoneNum} after {nextMonth}. Please remove {zoneNum} zones from QUALITIA DNS until {endOfMonth}.',
              {
                nextZoneNum,
                nextMonth: d(new Date(contractEndDate), 'ymd'),
                zoneNum: requireDescreaseAmount,
                endOfMonth: d(new Date(endDate), 'ymd'),
              }
            )
          } else {
            deleteZone.value = t(
              'You can use {currentZoneNum} now. Please remove {zoneNum} zones from QUALITIA DNS.',
              {
                currentZoneNum: nextZoneNum,
                zoneNum: requireDescreaseAmount,
              }
            )
          }
        }
      })
    }

    onMounted(() => update())

    const redirectTo = (url: string) => {
      router.replace(`/${locale}/${url}/`)
    }

    const onClick = () => {
      isOpen.value = !isOpen.value
    }

    return {
      navList,
      isOpen,
      isUnpaid,
      deleteZone,
      agreements,
      t,
      isMe,
      showUnpaid,
      onClick,
      redirectTo,
    }
  },
})
</script>

<style scoped lang="postcss">
.selected {
  @apply bg-blue-200;
}
</style>
