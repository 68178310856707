import { errorHandler } from '@/api/utils'
import { http } from '@/plugins/ky'

export const get = async () =>
  new Promise<boolean>((resolve) =>
    http
      .get('customer/payment/status')
      .then(async (r: any) => {
        const { paid } = await r.json()

        resolve(paid)
      })
      .catch(async ({ response, message }: any) => {
        await errorHandler(response, message)
        //TODO:エラーハンドリングきれいにしよう
      })
  )
