
import * as Sentry from '@sentry/browser'
import { get as getPaymentStatus } from '@/api/customer/payment/status'
import { get as getServiceStatus } from '@/api/customer/service/status'
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTranslation } from '@/locales'
import ServiceMenu from '@/components/Layout/ServiceMenu.vue'
import User from '@/components/Layout/User.vue'
import NavLink from '@/components/Layout/NavLink.vue'

export default defineComponent({
  components: {
    NavLink,
    ServiceMenu,
    User,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const isOpen = ref(true)
    const isUnpaid = ref(false)
    const { t, d } = useTranslation()
    const { locale } = route.params
    const agreements = ref('')

    const deleteZone = ref('')

    const navList = [
      { to: 'service/list', name: t('Service List') },
      { to: 'service/order', name: t('Buy') },
      { to: 'payment/invoices', name: t('Invoice') },
      { to: 'payment/history', name: t('Payment History') },
      { to: 'user/management', name: t('User management') },
    ]

    const showUnpaid = () =>
      !window.location.pathname.startsWith(`/${locale}/payment/card`)

    const isMe = (to: string) => {
      const result =
        window.location.pathname.startsWith(`/${to}`) ||
        window.location.pathname.startsWith(`/${locale}/${to}`)

      return result
    }

    const update = () => {
      agreements.value = `${process.env.VUE_APP_ORDER_APP}/${locale}/agreements`

      getPaymentStatus().then((paid) => (isUnpaid.value = !paid))
      getServiceStatus().then((status) => {
        const res = status.filter((s) => s.serviceName === 'qt-dns-zones')
        if (res.length && res[0].requireDescreaseAmount > 0) {
          const {
            contractEndDate,
            nextZoneNum,
            endDate,
            requireDescreaseAmount,
          } = res[0]
          if (
            contractEndDate != null &&
            nextZoneNum != null &&
            endDate != null
          ) {
            deleteZone.value = t(
              'You can use {nextZoneNum} after {nextMonth}. Please remove {zoneNum} zones from QUALITIA DNS until {endOfMonth}.',
              {
                nextZoneNum,
                nextMonth: d(new Date(contractEndDate), 'ymd'),
                zoneNum: requireDescreaseAmount,
                endOfMonth: d(new Date(endDate), 'ymd'),
              }
            )
          } else {
            deleteZone.value = t(
              'You can use {currentZoneNum} now. Please remove {zoneNum} zones from QUALITIA DNS.',
              {
                currentZoneNum: nextZoneNum,
                zoneNum: requireDescreaseAmount,
              }
            )
          }
        }
      })
    }

    onMounted(() => update())

    const redirectTo = (url: string) => {
      router.replace(`/${locale}/${url}/`)
    }

    const onClick = () => {
      isOpen.value = !isOpen.value
    }

    return {
      navList,
      isOpen,
      isUnpaid,
      deleteZone,
      agreements,
      t,
      isMe,
      showUnpaid,
      onClick,
      redirectTo,
    }
  },
})
