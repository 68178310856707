
import * as Sentry from '@sentry/browser'
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue'

export default defineComponent({
  setup() {
    const isOpen = ref(false)
    const refMenu = ref<HTMLElement | null>(null)
    const qtDnsUrl = process.env.VUE_APP_QUALITIA_DNS_URL

    const open = () => (isOpen.value = !isOpen.value)

    const click = (e: any) => {
      if (isOpen.value && refMenu.value != null) {
        if (!refMenu.value.contains(e.target)) {
          isOpen.value = false
        }
      }
    }

    onMounted(() => {
      window.addEventListener('click', click)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('click', click)
    })

    return {
      isOpen,
      qtDnsUrl,
      open,
      refMenu,
    }
  },
})
