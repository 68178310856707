<template>
  <div className="relative inline-block text-left" ref="refMenu">
    <fa-icon
      icon="th-large"
      class="text-2xl text-white cursor-pointer"
      @click="open"
    />
    <div
      v-if="isOpen"
      ref="{menuRef}"
      class="origin-top-right absolute right-0 mt-1 py-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
    >
      <a
        :href="qtDnsUrl"
        class="block py-1 px-4 text-sm w-full text-gray-600 font-bold hover:bg-blue-200 cursor-pointer"
      >
        QUALIITIA DNS
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/browser'
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue'

export default defineComponent({
  setup() {
    const isOpen = ref(false)
    const refMenu = ref<HTMLElement | null>(null)
    const qtDnsUrl = process.env.VUE_APP_QUALITIA_DNS_URL

    const open = () => (isOpen.value = !isOpen.value)

    const click = (e: any) => {
      if (isOpen.value && refMenu.value != null) {
        if (!refMenu.value.contains(e.target)) {
          isOpen.value = false
        }
      }
    }

    onMounted(() => {
      window.addEventListener('click', click)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('click', click)
    })

    return {
      isOpen,
      qtDnsUrl,
      open,
      refMenu,
    }
  },
})
</script>
