<template>
  <div className="relative inline-block text-left" ref="refMenu">
    <fa-icon
      icon="user-circle"
      class="text-3xl text-white cursor-pointer"
      @click="open"
    />
    <div
      v-if="isOpen"
      ref="{menuRef}"
      class="origin-top-right absolute right-0 mt-1 py-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
    >
      <div class="flex items-center py-1 px-2 cursor-pointer hover:bg-blue-200">
        <fa-icon icon="sign-out-alt" class="mr-1" />
        <p
          @click="openConfirm"
          class="block text-sm w-full text-gray-600 font-bold"
        >
          {{ t('Sign Out') }}
        </p>
      </div>
    </div>
  </div>
  <Dialog v-if="isConfirm">
    <fa-icon
      icon="times"
      @click="clickClose"
      :class="[inProgress ? 'text-gray-300' : 'cursor-pointer', 'm-1']"
    />
    <div class="px-4 py-1">
      <div class="flex items-center text-lg text-gray-600 mb-1">
        <fa-icon icon="sign-out-alt" class="mr-1" />
        <h1 class="font-bold">
          {{ t('Sign Out') }}
        </h1>
      </div>

      <p class="text-gray-800 text-base mb-2">
        {{ t('You are signing out now. Are you sure?') }}
      </p>
    </div>
    <div class="bg-gray-100 py-2 px-2 text-right">
      <button
        :class="[
          inProgress ? 'bg-blue-200' : 'bg-blue-500',
          'cursor-pointer text-white w-16 rounded-sm focus:outline-none',
        ]"
        @click="signOut"
      >
        {{ t('Yes') }}
      </button>
    </div>
  </Dialog>
</template>

<script lang="ts">
import * as Sentry from '@sentry/browser'
import { useTranslation } from '@/locales'
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue'
import { startSingleSignOut } from '@/auth'
import Dialog from '@/components/Layout/Dialog.vue'

export default defineComponent({
  components: {
    Dialog,
  },
  setup() {
    const { t } = useTranslation()
    const isOpen = ref(false)
    const isConfirm = ref(false)
    const inProgress = ref(false)
    const refMenu = ref<HTMLElement | null>(null)

    const open = () => (isOpen.value = !isOpen.value)

    const openConfirm = () => {
      isConfirm.value = true
      isOpen.value = false
    }

    const clickClose = () => {
      if (!inProgress.value) {
        isConfirm.value = false
      }
    }

    const click = (e: any) => {
      if (isOpen.value && refMenu.value != null) {
        if (!refMenu.value.contains(e.target)) {
          isOpen.value = false
        }
      }
    }

    const signOut = () => {
      inProgress.value = true
      startSingleSignOut()
    }

    onMounted(() => {
      window.addEventListener('click', click)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('click', click)
    })

    return {
      isOpen,
      refMenu,
      isConfirm,
      inProgress,
      clickClose,
      openConfirm,
      signOut,
      t,
      open,
    }
  },
})
</script>
