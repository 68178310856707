import { errorHandler } from '@/api/utils'
import { http } from '@/plugins/ky'

export type Status = {
  serviceName: string
  contractEndDate?: string
  nextZoneNum?: number
  endDate?: string
  requireDescreaseAmount: number
}

export const get = () =>
  new Promise<Status[]>((resolve, reject) =>
    http
      .get('customer/service/status')
      .then(async (r: any) => {
        const res = await r.json()
        resolve(res)
      })
      .catch(async ({ response, message }: any) => {
        await errorHandler(response, message)
        reject(undefined)
      })
  )
