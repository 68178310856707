
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['close'],
  setup(_, { emit }) {
    const close = () => emit('close')

    return {
      close,
    }
  },
})
