<template>
  <div x-data="{ open: false }" @click="close">
    <div
      class="absolute top-0 left-0 flex items-center justify-center w-full h-full z-20 overflow-hidden"
      style="background-color: 'rgba(0,0,0,.5)'"
      x-show="open"
    >
      <div class="h-auto p-0 m-0 text-left bg-white rounded shadow-xl z-50">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['close'],
  setup(_, { emit }) {
    const close = () => emit('close')

    return {
      close,
    }
  },
})
</script>
